import React from "react";

import Layout from "../../components/layout";
import Hero from "../../components/company/clients/hero";
import Customers from "../../components/company/clients/customers";
import SecondaryFooter from "../../components/layout/secondaryFooter";
import { graphql } from "gatsby";

export default function Clients(props) {
  if (!props.data) return null;
  const data = props.data.allPrismicClients.edges[0].node.dataRaw;
  return (
    <Layout>
      <Hero data={data} />
      <Customers data={data.body[0]} />
      <SecondaryFooter />
    </Layout>
  );
}

export const client = graphql`
  query client {
    allPrismicClients {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`;
