import React from "react";
import left from "../../../../assets/company/left.svg";
import right from "../../../../assets/company/right.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./style.scss";

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <button
      className="team-about-right-arrow"
      style={{
        ...style,
        display: "block",
        width: "63px",
        position: `absolute`,
        top: `calc(50% - 12px)`,
        right: `-45px`,
        cursor: `pointer`,
      }}
      onClick={onClick}
    >
      <img src={right} alt="right" />
    </button>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <button
      className="team-about-left-arrow"
      id="left-arrow-slick-about"
      style={{
        ...style,
        position: `absolute`,
        top: `calc(50% - 12px)`,
        width: "63px",
        cursor: "pointer",
        zIndex: "10",
        left: "-45px",
      }}
      onClick={onClick}
    >
      <img src={left} alt="right" />
    </button>
  );
}

export default function Carousel({ data }) {
  const [curr, setCurr] = React.useState(1);
  const handleChange = (index) => {
    setCurr(index + 1);
  };
  const len = data.length;
  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <section className="carousel_section">
      {/* Carousel */}
      <div className="carousel_container">
        <Slider {...settings} afterChange={handleChange}>
          {data.map((list, index) => {
            return (
              <div key={index} className="carousel-image-container">
                <img src={list.client_image.url} alt="" />
              </div>
            );
          })}
        </Slider>
        <div>
          <p>
            {curr}/{len}
          </p>
        </div>
      </div>
    </section>
  );
}
