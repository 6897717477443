import React from "react";
import Carousel from "./Carousel";
import "./style.scss";

export default function Customers({ data }) {
  return (
    <section className="clients-customers">
      <div className="container">
        {data.items.map((list, index) => {
          return (
            <div key={index} className="client-image-container">
              <img src={list.client_image.url} alt="" />
            </div>
          );
        })}
      </div>
      <Carousel data={data.items} />
    </section>
  );
}
