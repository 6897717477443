import { RichText } from "prismic-reactjs";
import React from "react";

import "./style.scss";

export default function Hero({ data }) {
  return (
    <section className="clients-hero">
      <div className="container">
        <RichText render={data.hero_title} />
        <RichText render={data.description_one} />
        <RichText render={data.description_two} />
      </div>
    </section>
  );
}
